import React from "react";
import { Container, Table, Image, Card } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import H1 from "../images/cmpy.png"

console.log(H1); // MV image

const CompanyPage = () => (
  <Layout>
    <SEO title="Company" />
    <Image src={H1} fluid />
    <Container>
<br></br>
    <Table striped bordered>
  <thead>
    <tr>
      <th>Company name</th>
      <th>HITSVILLE V.N. COMPANY LIMITED</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th>CEO</th>
      <th>Kento Nakatoki</th>
    </tr>
  </tbody>
  <thead>
    <tr>
      <th>Primary Office</th>
      <th>Tầng 5, Tòa nhà Songdo, 62A Phạm Ngọc Thạch, Phường 06, Quận 3, Thành phố Hồ Chí Minh, Việt Nam</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th>Established</th>
      <th>16th JAN, 2019</th>
    </tr>
  </tbody>
  <thead>
    <tr>
      <th>Website</th>
      <th>https://www.hitsville.vn</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th>Mail</th>
      <th>info@hitsville.vn (English / Japanese)</th>
    </tr>
  </tbody>
  <thead>
    <tr>
      <th>TEL</th>
      <th>+84 28 38 226 329 (Vietnamese)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th>Other</th>
      <th>運営は日本人、ベトナム人が担当しております。お気軽に日本語、ベトナム語、英語でお問い合わせください。<br></br>
      Our operation is staffed by Japanese and Vietnamese. Please feel free to contact us in Japanese, Vietnamese or English.</th>
    </tr>
  </tbody>
</Table>
</Container>
  </Layout>
);
export default CompanyPage;
